:root {
	--color-white: #fff;
	--color-light: #f3f3f3;
	--color-black: #121212;
	--color-night: #001632;
	--color-red: #f44336;
	--color-blue: #1a73e8;
	--color-gray: #80868b;
	--color-grayish: #dadce0;
	--shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html {
	font-size: 100%;
	font-size-adjust: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;
}
*, *::before, *::after {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
	list-style: none;
	list-style-type: none;
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
body {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: var(--color-black);
	background-color: var(--color-light) !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}