.container-scroller {
  padding: 0 1rem 1rem 1rem;
  margin-top: 56px;
}
.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
.card {
  border-radius: 0px;
}

.card-body {
  padding: 15px;
}

.card-header {
  font-weight: 600;
}

.remove-icons {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  top: -14px;
  right: 10px;
  text-align: center;
  padding: 0;
  line-height: 14px;
}

.full-page-loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 100;
  text-align: center;
  width: 100%;
  height: 100vh;
  background: #0000004f;
  display: flex;
  align-items: center;
  justify-content: center;
}

table td,
table th {
  font-size: 12px;
  vertical-align: middle;
}

.payments table td,
.payments table th {
  vertical-align: top;
}

.no-data-found-text {
  padding: 40px 20px;
  text-align: center;
  min-height: calc(100vh - 114px);
}

@media only screen and (max-width: 600px) {
  .container-scroller {
    padding: 0;
  }
}