a, button {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	cursor: pointer;
	border: none;
	outline: none;
	background: none;
	text-decoration: none;
}
img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
}
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 80rem;
	min-height: 100vh;
	width: 100%;
	padding: 0 2rem;
	margin: 0 auto;
}
.ion-logo-apple {
	font-size: 1.65rem;
	line-height: inherit;
	margin-right: 0.5rem;
	color: var(--color-black);
}
.ion-logo-google {
	font-size: 1.65rem;
	line-height: inherit;
	margin-right: 0.5rem;
	color: var(--color-red);
}
.ion-logo-facebook {
	font-size: 1.65rem;
	line-height: inherit;
	margin-right: 0.5rem;
	color: var(--color-blue);
}
.text {
	font-family: inherit;
	line-height: inherit;
	text-transform: unset;
	text-rendering: optimizeLegibility;
}
.text-large {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-black);
}
.text-normal {
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-black);
}
.text-links {
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-blue);
}
.text-links:hover {
	text-decoration: underline;
}
.main .wrapper {
	max-width: 28rem;
	width: 100%;
	margin: 2rem auto;
	padding: 2rem 2.5rem;
	border: none;
	outline: none;
	border-radius: 0.25rem;
	color: var(--color-black);
	background: var(--color-white);
	box-shadow: var(--shadow-large);
}
.main .wrapper .form {
	width: 100%;
	height: auto;
	margin-top: 2rem;
}
.main .wrapper .form .input-control {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.25rem;
}
.main .wrapper .form .input-field {
	font-family: inherit;
	font-size: 1rem;
	font-weight: 400;
	line-height: inherit;
	width: 100%;
	height: auto;
	padding: 0.75rem 1.25rem;
	border: none;
	outline: none;
	border-radius: 2rem;
	color: var(--color-black);
	background: var(--color-light);
	text-transform: unset;
	text-rendering: optimizeLegibility;
}
.main .wrapper .form .input-submit {
	font-family: inherit;
	font-size: 1rem;
	font-weight: 500;
	line-height: inherit;
	cursor: pointer;
	min-width: 40%;
	height: auto;
	padding: 0.65rem 1.25rem;
	border: none;
	outline: none;
	border-radius: 2rem;
	color: var(--color-white);
	background: var(--color-blue);
	box-shadow: var(--shadow-medium);
	text-transform: capitalize;
	text-rendering: optimizeLegibility;
}
.main .wrapper .striped {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
}
.main .wrapper .striped-line {
	flex: auto;
	flex-basis: auto;
	border: none;
	outline: none;
	height: 2px;
	background: var(--color-grayish);
}
.main .wrapper .striped-text {
	font-family: inherit;
	font-size: 1rem;
	font-weight: 500;
	line-height: inherit;
	color: var(--color-black);
	margin: 0 1rem;
}
.main .wrapper .method-control {
	margin-bottom: 1rem;
}
.main .wrapper .method-action {
	font-family: inherit;
	font-size: 0.95rem;
	font-weight: 500;
	line-height: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	padding: 0.35rem 1.25rem;
	outline: none;
	border: 2px solid var(--color-grayish);
	border-radius: 2rem;
	color: var(--color-black);
	background: var(--color-white);
	text-transform: capitalize;
	text-rendering: optimizeLegibility;
	transition: all 0.35s ease;
}
.main .wrapper .method-action:hover {
	background: var(--color-light);
}

@media only screen and (max-width: 600px) {
	.main .wrapper {
		margin: 1rem auto;
		padding: 1rem 1rem;
	}
}